import React from "react";
import {Book} from './book';
import PropTypes from "prop-types";
import {Review} from './review';

import * as styles from './featured.module.scss';




export class Featured extends React.Component {
    render() {
		const book = this.props.book;
		const onLeft = this.props.onLeft;
        return (
            <div className={styles.container}>
            	<Book book={book} featured={true} onLeft={onLeft}/>     
            

            	<div className={styles.featuredReviews}>
            		{book.reviews.map((r, i) => <Review key={i} review={r} featured={true} last={i === book.reviews.length - 1}/>)}	           		
            	</div>
           
            </div>
        );
    }
}

Featured.propTypes = {
	book: PropTypes.object.isRequired,
	onLeft: PropTypes.bool.isRequired
};