import * as React from 'react';
import * as styles from './page.module.scss';

export class Page extends React.Component {
	

    render() {
        return (
        
            <div  className={styles.page} >
            

                {this.props.children}
            </div>
        );
    }
}