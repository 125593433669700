import React from "react";
import PropTypes from "prop-types";
import * as styles from './review.module.scss';


export class Review extends React.Component {
	
    getContainerClass() {
        const feat = this.props.featured ? `${styles.container} ${styles.featured}` : styles.container;
        return this.props.last ? `${feat} ${styles.last}` : feat;
    }

    getSourceClass() {
        return this.props.featured ? `${styles.source} ${styles.featured}` : styles.source;

    }

    getContentClass() {
        return this.props.featured ? `${styles.content} ${styles.featured}` : styles.content;

    }

    render() {
        return (
            <div className={this.getContainerClass()}>

                {this.props.children}
                <div className={this.getContentClass()}>
                    {this.props.review.content}
                </div>
                <p className={this.getSourceClass()}>{this.props.review.source.toUpperCase()}</p>
            </div>
        );
    }
}


Review.propTypes = {
    review: PropTypes.object.isRequired,
    last: PropTypes.bool.isRequired,
    featured: PropTypes.bool.isRequired,


};

Review.defaultProps = {
    featured: false
};


