import React from "react";
import {ContentPage} from "../contentPage";
import {Section} from "../section";

import * as styles from './contactPage.module.scss';




export class ContactPage extends React.Component {
    render() {
        return (
            <ContentPage activePage={3}>
                <Section>
                	<p>For all enquiries please contact James Hawes at</p>
                	<p className={styles.email}>jameshawesbooks@gmail.com</p>
                	<p>or Caspian Dennis at</p>
                	<p className={styles.email}>caspian@abnerstein.co.uk</p>
                </Section>
           
            </ContentPage>
        );
    }
}