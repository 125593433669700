import React from "react";
import {Pages} from "../config/pages";
import img1 from '../content/img/books/theshortest.jpg';
import img3 from '../content/img/books/englhuns.jpg';
import img4 from '../content/img/books/kafka.jpg';
import img5 from '../content/img/books/armalite.png';
import img6 from '../content/img/books/speakfor.png';
import img7 from '../content/img/books/whitepowder2.jpg';
import img8 from '../content/img/books/dead.jpg';
import img9 from '../content/img/books/rancid.jpg';
import img10 from '../content/img/books/merc2.jpg';
import img11 from '../content/img/books/tshoengland.jpg';
import img12 from '../content/img/books/brilliantisles.jpg';
import img13 from '../content/img/books/agbabi.jpg';
import img20 from '../content/img/books/dunant.jpg';
import img14 from '../content/img/books/girlbehindwall.jpg';
import img15 from '../content/img/books/goldenhand.jpg';
import img16 from '../content/img/books/meetmeatthemuseum.jpg';
import img17 from '../content/img/books/mynameisleon.jpg';
import img18 from '../content/img/books/ringmaster.jpg';
import img19 from '../content/img/books/thewell.jpg';


export const menuItems = [
    {location: Pages.about.home, name: "About"},
    {location: Pages.home, name: "Books"},
    {location: Pages.teaching.home, name: "Mentoring"},
    {location: Pages.contact.home, name: "Contact"},
];

export const testaments = [
    {author: "Kit de Waal", quote: "James Hawes was the first person who made me believe that I could write.  His confidence in a new Creative Writing  student who didn’t get to University until she was 51, made all the difference and helped me realise my ambitions.", img: img17},
    {author: "Anne Youngson", quote: "James Hawes is an outstanding Creative Writing tutor, and I can best describe why by describing what he does not do, as well as what he does. He does not mislead with false positives. He does not dissect the text like a copy editor. He has the gift of seeing how a piece of writing can be made to work better, and in my experience this is rare indeed. If something is wrong, he will say why it is wrong, and offer suggestions on putting it right, but leave it to the writer to make decisions on his or her own work. In other words, James encourages the development of the writer’s own critical faculties, which in my opinion is the only way to becoming a better writer.", img: img16},
    {author: "Catherine Chanter", quote: <>James Hawes was the right tutor at the right time for me. Just as I was working on the MS for my first novel, <em>The Well</em>, he gave me the confidence I needed to keep going, but also the challenge I needed to revisit and redraft. With disarming humour, he was always able to deliver difficult news about shortcomings in my writing in a way that made it almost palatable; it's equally true that he clearly derived a great deal of pleasure from getting to know his students as individuals and revelled in any success they might achieve. The writing tasks he set often took us to the edge of our writing comfort zone, which is how it should be. A lot of writing is about hard graft. Somehow James made that fun.</>, img: img19},
    {author: "Mandy Robotham", quote: "Beyond James' boundless enthusiasm for literature there's a depth of knowledge that's so valuable to any student of creative writing. What's more, he's intent on drawing out and nurturing the talents of new writers.", img: img14},
    {author: "LP Fergusson", quote: "James Hawes had faith in my writing that I, with five abandoned manuscripts and hundreds of rejections, had completely lost. Under his guidance my historical writing moved from clunky and over researched to trim and exciting – so much so that it snagged a top London agent and clinched a two-book publishing deal. I feel privileged that James continues to champion my writing career, and his many words of wisdom remain with me to this day.", img: img15},
    {author: "Carly Schabowski", quote: "I have had the pleasure of not only being one of James Hawes's MA students in creative writing at Oxford Brookes University, but have also benefited from his critical and creative advice as my PhD supervisor. He instinctively knows what will make a truly great piece of writing, and is passionate about helping others develop their skills. A great mentor, teacher and character who will make you glad you joined his course!", img: img18},
    {author: "Sarah Dunant", quote: "How to sum up James Hawes in words? Having worked with him for over a decade, I could start by saying that as a teacher he is substantial, compassionate, attentive and knowledgeable. I would add that both in – and out – of the classroom, he is infectiously good-natured and humorous. Put all that together with his acute intellect and experience and it creates an environment where people feel both safe and, at the same time, willing to be challenged, to take risks with their work and learn, not just from him, but from one another. Creative writing doesn't come much better than that.", img: img20},
    {author: "Patience Agbabi", quote: "Since 2009, I've worked repeatedly with James Hawes at the Creative Writing department at Oxford Brookes University. His passion for writing of all genres, acute criticism of manuscripts and effervescent personality make him brilliantly suited to running a creative writing house. Enjoy!", img: img13},
];


const r1 = {source: "Spectator", content: <p>"An excellent little book&hellip; [Hawes] knows what he's on about and his conclusions are measured, but he favours clear, concise prose over dense academese. He has a sense of humour, and a sharp eye for similarities between then and now."</p>};
const r3 = {source: "New European", content: <p>"An excellent, elegantly written overview of German history from the Iron Age to Angela Merkel's chancellorship&hellip; Authoritative and accessible."</p>};
const r2 = {source: "The Tablet", content: <p>"A daring attempt to remedy the ignorance of the centuries in little over 200 pages&hellip; not just an entertaining canter past the most prominent landmarks in German history &ndash; also a serious, well-researched and radical rethinking of the continuities in German political life."</p>};
const r4 = {source: "The Times", content: <p>"Performed with wit and finesse&hellip; his book is full of enlightening surprises&hellip; [Hawes] is an admirable guide, leading us through this tangled intellectual copse."</p>};
const r5 = {source: "Scotsman", content: <p>"This wonderful book, a revelation and a scream&hellip; wonderfully irreverent."</p>};
const r6 = {source: "Telegraph", content: <p>"Very witty&hellip; Both laddish and slyly intelligent, Hawes has his cake and eats it."</p>};
const r7 = {source: "Observer", content: <p>"Amusing and intelligent throughout&hellip; The tone of the narrative is pitch-perfect."</p>};
const r8 = {source: "Guardian", content: <p>"Hawes's cinematic sixth sense ensures that <em>White Powder</em> is well up to speed, with lots of rapid-fire 1940s-style patter, quick scene cuts and jokes."</p>};
const r9 = {source: "The Times", content: <p>"The sassy, cutting narrative deftly reinforces the argument&hellip; and delivers an extremely entertaining read."</p>};
const r10 = {source: "The Times", content: <p>"I have been racking my brains and I cannot remember a book I have read recently which made me laugh more, or as often, as James Hawes's <em>Rancid Aluminium</em>&hellip; It is therefore no exaggeration to say that <em>Rancid Aluminium</em> made me laugh more than anything I have read in the past ten years."</p>};
const r11 = {source: "Spectator", content: <p>"A very comic and brilliant book&hellip; James Hawes has adapted an enjoyable narrative poise of cynical idealism, both a loving and a loathing of modern Britain&hellip; A new novelist of prodigious talent."</p>};
const r12 = {source: "Philip Pullman", content: <p>"James Hawes's view of English history is sharp and vivid and extremely persuasive."</p>};
const r13 = {source: "Nick Cohen", content: <p>"No one writes history as well as James Hawes or uses the past to make sense of the present so skilfully. This is an urgent and electrifying work that takes you to the heart of England's sickness. Do yourself a favour and read it."</p>};
const r14 = {source: "New European", content: <p>"a thorough and absorbing investigation of the English story&hellip; Perhaps [Hawes's] greatest skill is in how he brings the story right up to date, able to step back from the current madness with admirable clarity to separate wood and trees in a way most of us would find impossible."</p>};

export const brilliantIsles = {reviews: [], img: img12, clickToBuyText: "", link: "https://www.waterstones.com/book/brilliant-isles/james-hawes/9781913083045", title: "Brilliant Isles"};

export const books = [
    {reviews: [r12, r13, r14], img: img11, clickToBuyText: "", link: "https://www.waterstones.com/book/the-shortest-history-of-england/james-hawes/9781910400692", title: "The Shortest History of England", bannerText: "The Shortest History of England: Times #4 Bestseller (August 2021)"},
    {reviews: [r1, r2, r3], img: img1, clickToBuyText: "", link: "https://www.waterstones.com/book/the-shortest-history-of-germany/james-hawes/9781910400739", title: "The Shortest History of Germany", bannerText: "The Shortest History of Germany: #2 Sunday Times Bestseller"},
    // {img: img2, country: "</br> the US edition", link: "https://theexperimentpublishing.com/catalogs/spring-2019/the-shortest-history-of-germany/", title: "The Shortest History of Germany"},
    {reviews: [r4], img: img3, clickToBuyText: "", link: "https://www.amazon.co.uk/Englanders-Huns-Culture-Clash-which-First/dp/0857205293/ref=sr_1_1_twi_pap_1?s=books&ie=UTF8&qid=1535788317&sr=1-1&keywords=englanders+and+huns", title: "Englanders and Huns"},
    {reviews: [r5], img: img4, clickToBuyText: "", link: "https://www.amazon.co.uk/Excavating-Kafka-James-Hawes/dp/184916164X/ref=sr_1_1?s=books&ie=UTF8&qid=1535788364&sr=1-1&keywords=excavating+kafka", title: "Excavating Kafka"},
    {reviews: [r6], img: img5, clickToBuyText: "", link: "https://www.amazon.co.uk/My-Little-Armalite-James-Hawes/dp/0099513250/ref=sr_1_1_twi_pap_1?s=books&ie=UTF8&qid=1535788430&sr=1-1&keywords=my+little+armalite", title: "My Little Armalite"},
    {reviews: [r7], img: img6, clickToBuyText: "", link: "https://www.amazon.co.uk/Speak-England-James-Hawes/dp/0099470179/ref=sr_1_1?s=books&ie=UTF8&qid=1535788478&sr=1-1&keywords=speak+for+england", title: "Speak for England"},
    {reviews: [r8], img: img7, clickToBuyText: "", link: "https://www.amazon.co.uk/White-Powder-Green-Light-James/dp/0099442086/ref=sr_1_1_twi_pap_1?s=books&ie=UTF8&qid=1535788518&sr=1-1&keywords=white+powder+green+light", title: "White Powder, Green Light"},
    {reviews: [r9], img: img8, clickToBuyText: "", link: "https://www.amazon.co.uk/Dead-Long-Enough-J-Hawes/dp/0099759810/ref=sr_1_1_twi_pap_2?s=books&ie=UTF8&qid=1535788571&sr=1-1&keywords=dead+long+enough", title: "Dead Long Enough"},
    {reviews: [r10], img: img9, clickToBuyText: "", link: "https://www.amazon.co.uk/Rancid-Aluminium-James-Hawes/dp/0099759713/ref=sr_1_1_twi_pap_1?s=books&ie=UTF8&qid=1535788624&sr=1-1&keywords=rancid+aluminium", title: "Rancid Aluminum"},
    {reviews: [r11], img: img10, clickToBuyText: "", link: "https://www.amazon.co.uk/White-Merc-Fins-James-Hawes/dp/0099591510/ref=pd_sbs_14_1?_encoding=UTF8&pd_rd_i=0099591510&pd_rd_r=a7b11fcd-adbc-11e8-8dbb-41abd883f66d&pd_rd_w=GNDBA&pd_rd_wg=nEikQ&pf_rd_i=desktop-dp-sims&pf_rd_m=A3P5ROKL5A1OLE&pf_rd_p=85d62760-2a0e-407d-aa36-f3c03afc01c3&pf_rd_r=PAB0DR6QB9HCY5H7DBBB&pf_rd_s=desktop-dp-sims&pf_rd_t=40701&psc=1&refRID=PAB0DR6QB9HCY5H7DBBB", title: "A White Merc with Fins"},
];

export const name = "James Hawes";


export const fontLoadDuration = 1000 / 4;


export default function getYear() {
    const d = new Date();
    return d.getFullYear();

}
