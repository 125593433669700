import React from "react";
import {ContentPage} from "../contentPage";
import {Banner} from '../banner';
import portrait from "../../img/portrait.jpg";
import {Section} from "../section";

import * as styles from './aboutPage.module.scss';




export class AboutPage extends React.Component {
    render() {
        return (
            <ContentPage activePage={0}>
            	<Section>
            		<div className={styles.imageContainer}>
	                    <img src={portrait} className={`${styles.image} ${styles.clearfix}`} alt="James Hawes"/>
	                </div>
	                	<p className={styles.byIMG}>James Hawes grew up in Gloucestershire, Edinburgh and Shropshire. He took a First in German at Hertford College, Oxford, then did a postgrad theatre studies in Cardiff, Wales. Having failed as an actor, he worked as an English teacher in Spain. In 1985-6 he was in charge of CADW excavations at the now-UNESCO World Heritage site of Blaenavon Ironworks. He took a PhD on Nietzsche and German literature 1900-1914 at University College, London 1987-90, then lectured in German at Maynooth University (Ollscoil Mhá Nuad) in Ireland between 1989 and 1991 before doing so at Sheffield University and Swansea University.</p>	
	            </Section>


                <Banner margin={false} color="darkred">Writing Career</Banner>

                <Section>
                	<p>James has published six novels, all with Jonathan Cape. He turned to creative non-fiction with a Kafka anti-biography, <em>Excavating Kafka</em> (2008) which became the subject of a BBC documentary. In 2015, <em>Englanders and Huns</em> was shortlisted for the Paddy Power Political Books of the Year 2015. <em>The Shortest History of Germany</em>, published in May 2017, reached #2 in the Sunday Times bestseller charts in April 2018, being pipped for #1 only by Noah Yuval Harari. <em>The Shortest History of England</em> appeared in October 2020 and reached #4 in the Times bestseller charts in July 2021.</p>
                	<p className={styles.par}>James has reviewed and/or written for every UK broadsheet, on topics from DIY to Prince Philip. His journalistic high-points to date were the cover-story for <em>The New Statesman</em> in September 2017 and the long read <em>The England Delusion</em> in <em>Prospect</em> in August 2021; this was publicly described by Prof Ciaran Martin, CB, founding Chief Executive  of  the UK’s National Cyber Security Centre, as “a really brilliant essay on the historical origins of UK constitutional tensions”. He has appeared on Radio 4 Today, Channel 4 News, Sky News and GB News.</p>
                	<p className={styles.par}>In 2022, he was “series story consultant” and key on-screen commentator in the eight-part BBC TV series “Art that Made Us”. He also wrote the accompanying book.</p>
                	<p className={styles.par}>His next book will be <em>The Shortest History of Ireland</em>.</p>
                </Section>

           
            </ContentPage>
        );
    }
}