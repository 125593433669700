import React from "react";
import PropTypes from "prop-types";
import * as styles from './book.module.scss';

export class Book extends React.Component {
	
    getContainerClass() {
        return this.props.featured ? `${styles.container} ${styles.featured} ${this.props.onLeft ? '' : styles.germany }` : styles.container;
    }

    getLinkClass() {
        return this.props.featured ? `${styles.link} ${styles.featured}` : styles.link;
    }

    getImageClass() {
        return this.props.featured ? `${styles.image} ${styles.featured}` : styles.image;
    }

    getLabelClass() {
        return this.props.onLeft ? `${styles.label} ${styles.onLeft}` : `${styles.label} ${styles.onRight}`;
    }

    getCornerClass() {
        return this.props.onLeft ? `${styles.corner} ${styles.onLeft}` : `${styles.corner} ${styles.onRight}`;
    }

    render() {
        return (
            <div className={this.getContainerClass()}>
            	<a className={this.getLinkClass()} href={this.props.book.link}>
                    <img src={this.props.book.img} className={this.getImageClass()} alt={this.props.book.title}/>
                    {this.props.featured && 
                        <div className={this.getLabelClass()}>
                            <div className={this.getCornerClass()}/>
                            <p>Click to buy{this.props.book.clickToBuyText}</p>
                        </div>
                        }

                </a>
            </div>
        );
    }
}


Book.propTypes = {
    book: PropTypes.object.isRequired,
    featured: PropTypes.bool.isRequired,
    onLeft: PropTypes.bool,
  

};

Book.defaultProps = {
    featured: false,
};

