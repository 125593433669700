import * as React from 'react';
import * as styles from './footerV.module.scss';
import getYear, {name} from '../../common/contentAux';



export class Footer extends React.Component {



    render() {
        return (
        	
            <div className={styles.footer}>
            	<p>Copyright &copy; {getYear()} {name}</p>
                <p>powered by &and;&#9679;&and;</p>
    		</div>
        );
    }
}