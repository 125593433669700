import React from "react";
import PropTypes from "prop-types";
import {Link} from './link';

import * as styles from './menu.module.scss';



export class MenuItem extends React.Component {
    

    getMenuItemClass() {
        const act = this.props.activePage ? `${styles.menuItem} ${styles.activePage}` : styles.menuItem;
        return this.props.index === 0 ? act : `${act} ${styles.marginLeft}`;
    }

    render() {
        return (
        	<Link location={this.props.menuItem.location} className={this.getMenuItemClass()}>
                    {this.props.menuItem.name}

            </Link>
        );
    }}


MenuItem.propTypes = {
   
    activePage: PropTypes.bool.isRequired,
    menuItem: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,

};

