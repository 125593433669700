
import React from 'react';
import { render } from 'react-dom';
import { Root } from './root';




render(<Root/>, document.getElementById('root'));

