import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import * as styles from './banner.module.scss';

const Container = styled.div`background-color: ${props => props.color}`;



export class Banner extends React.Component {
 	getContainerClass() {
        return this.props.margin ? `${styles.banner} ${styles.margin}` : styles.banner;
    }

    render() {
        return (
            

				<Container color={this.props.color} className={this.getContainerClass()}>{this.props.children}</Container>

            	
        );
    }
}

Container.propTypes = {
    color: PropTypes.string.isRequired,
    margin: PropTypes.bool.isRequired,
  
  

};

Container.defaultProps = {
	margin: true,
}