import React from "react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";

export class Link extends React.Component {
    render() {
        return (
            <NavLink className={this.props.className} to={this.props.location} exact={true}>
                {this.props.children}
            </NavLink>
        );
    }
}

Link.propTypes = {
    className: PropTypes.any,
    location: PropTypes.string.isRequired,
};