import React from "react";
import PropTypes from "prop-types";
import * as styles from './singleBook.module.scss';
import { Section } from "../section";

export class SingleBook extends React.Component {
	
    getContainerClass() {
        return `${styles.container} ${this.props.containerStyle}`;
    }

    getLinkClass() {
        return `${styles.link} ${this.props.linkStyle}`;
    }

    getImageClass() {
        return `${styles.image} ${this.props.imageStyle}`;
    }

    getLabelClass() {
        return this.props.onLeft ? `${styles.label} ${styles.onLeft}` : `${styles.label} ${styles.onRight}`;
    }

    getCornerClass() {
        return this.props.onLeft ? `${styles.corner} ${styles.onLeft}` : `${styles.corner} ${styles.onRight}`;
    }

    render() {
        return (
            <>
            <div className={styles.mobileContainer}>
                <Section>
                    <div className={styles.text}>
                        <p className={styles.header0}>Based on the landmark BBC series <em>Art That Made Us</em>:</p>
                    </div>
                    <a className={this.getLinkClass()} href={this.props.book.link}>
                        <div className={styles.imageContainer}>
                            <img src={this.props.book.img} className={styles.image} alt={this.props.book.title}/>
                        </div>
                        <div className={styles.label}>
                                <div className={styles.corner}/>
                                <p>Click to buy</p>
                            </div>
                    </a>
                    <div className={styles.text}>
                        <p className={styles.header}>Explore the history of the British isles through 80 extraordinary creations – from Beowulf and the Bayeux Tapestry to Banksy, Stormzy, and beyond...</p>
                        <p className={styles.desc}>A companion book to the landmark BBC series, <em>Brilliant Isles</em> tells the story of these islands through art, music, buildings and literature – the creations of visionaries, mavericks and rule-breakers who responded to their times and shaped the future.</p>
                    </div>
                </Section>
            </div>
            <div className={this.getContainerClass()}>
            	<a className={this.getLinkClass()} href={this.props.book.link}>
                    <img src={this.props.book.img} className={styles.image} alt={this.props.book.title}/>
                    <div className={styles.label}>
                            <div className={styles.corner}/>
                            <p>Click to buy</p>
                        </div>
                </a>
                <div className={styles.text}>
                    <p className={styles.header0}>Based on the landmark BBC series <em>Art That Made Us</em></p>
                    <p className={styles.header}>Explore the history of the British isles through 80 extraordinary creations – from Beowulf and the Bayeux Tapestry to Banksy, Stormzy, and beyond...</p>
                    <p className={styles.desc}>A companion book to the landmark BBC series, <em>Brilliant Isles</em> tells the story of these islands through art, music, buildings and literature – the creations of visionaries, mavericks and rule-breakers who responded to their times and shaped the future.</p>
                </div>
            </div>
            </>
        );
    }
}


SingleBook.propTypes = {
    book: PropTypes.object.isRequired,
    onLeft: PropTypes.bool,
    containerStyle: PropTypes.object,
    linkStyle: PropTypes.object,
    imageStyle: PropTypes.object,
  

};

SingleBook.defaultProps = {
    containerStyle: {},
    linkStyle: {},
    imageStyle: {},
};

