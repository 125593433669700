import React from "react";

import * as styles from './section.module.scss';




export class Section extends React.Component {
    render() {
        return (
            	<div className={styles.container}>
            		{this.props.children}
                </div>

                

           
        );
    }
}