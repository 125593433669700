import React from "react";
import {ContentPage} from "../contentPage";
import {Featured} from './featured';
import {Banner} from '../banner';
import {BookAndReview} from './bookAndReview';
import {books, brilliantIsles} from '../../../common/contentAux';
import * as styles from './booksPage.module.scss';
import { SingleBook } from "./singleBook";


export class BooksPage extends React.Component {

    render() {
        
        const bestsellerBanner= (bannerText) => <div className={styles.bannerContainer}>
            <Banner color="grey">{bannerText}</Banner>
        </div>;

        return (
            <ContentPage activePage={1}>

                <SingleBook  book={brilliantIsles}></SingleBook>

                {bestsellerBanner(books[0].bannerText)}

            	<div className={styles.featuredContainer}>
                    {books.slice(0, 2).map((book, i) => 
                        <div key={i}>
                            {(i <= 1) && bestsellerBanner(book.bannerText)}
                            <Featured key={i} book={book} onLeft={i % 2 === 0} />
                        </div>
                    )}
                </div>

				<Banner color="grey">Other books by James Hawes</Banner>

            	<div className={styles.otherBooksContainer}>
            		{books.slice(2).map((book, i) => <BookAndReview book={book} key={i} last={i === books.slice(2).length - 1}/>)}
            	</div>
           
            </ContentPage>
        );
    }
}