import React from "react";
import { testaments } from "../../../common/contentAux";
import { Pages } from "../../../config/pages";
import {ContentPage} from "../contentPage";
import {Section} from "../section";
import * as styles from './teachingPage.module.scss';

export class TeachingPage extends React.Component {
    getTestamentClass(i) {
        return i % 2 === 1 ? `${styles.testament} ${styles.odd}` : styles.testament;
    }

    getImgClass(i) {
        return i % 2 === 1 ? `${styles.image} ${styles.odd}` : styles.image;
    }

    render() {

        return (
            <ContentPage activePage={2}>
                <Section>
                	<p>James has taught since 2005 on the Oxford University MSt in creative writing, and was head of
examinations there for three years. In 2009, he was recruited to help found the Oxford Brookes
University MA in creative writing, where he is now the senior teacher. He has been External
Examiner on many courses, including the top-ranked MA in creative writing at UAE.</p>
                	<p className={styles.par}>From 2022, James offers private tuition/mentorship to writers. Here are some things his former
students and fellow-tutors have said about him:</p>
                </Section>

                <div className={styles.container}>
                    {testaments.map((testament, i) => <div key={i} className={this.getTestamentClass(i)}>
                        <img src={testament.img} className={this.getImgClass(i)} alt={`${testament.author}'s cover`}/>
                        <div className={styles.text}>
                            <p className={styles.quote}>{testament.quote}</p>
                            <p className={styles.author}>{testament.author}</p>
                        </div>
                    </div>)}
                </div>
               
               <Section>
                <p className={styles.par0}>The way James works with you – time-frame, online or in person, conditions, fees and suchlike – is
    agreed individually, once he has seen a sample of your writing.</p>
                <p className={styles.par}>He will only take on writers who he feels he can genuinely help towards completing a work,
    getting an agent, and publication.</p>
                <p className={styles.par}>This may mean that you already have a substantial project well in hand and in need of a final push
    to get you over the line – but it could equally mean that you have an idea you're excited about, still
    at a very early stage: James believes, on the basis of experience, that he can quickly tell whether a
    writer and their idea have what it takes, and what they need.</p>
                <p className={styles.par}>You can get in touch via the details in the <a className={styles.link} href={Pages.contact.home}>Contact</a> section.</p>
               </Section>
            </ContentPage>
        );
    }
}