export const subd = process.env.PUBLIC_URL;

export const Pages = {
    home: "/",
    about: {
        home: "/about",
    },
    teaching: {
        home: "/mentoring",
    },
    contact: {
        home: "/contact",
    },
};