import React from "react";
import PropTypes from "prop-types";
import {menuItems} from'./contentAux';
import {MenuItem} from './menuItem';

import * as styles from './menu.module.scss';






export class Menu extends React.Component {
    
    

    render() {
        return (
        	<div  className={styles.menu} >
                {menuItems.map((item, i) => 
                        <MenuItem 
                        key={i} 
                        index={i}
                        menuItem={item} 
                        activePage = {this.props.activePage === i}
                      
                        />)}

            </div>
        );
    }}



Menu.propTypes = {
    activePage: PropTypes.number.isRequired,
  

};





