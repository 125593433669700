import React from "react";
import PropTypes from "prop-types";
import {Book} from "./book";
import {Review} from "./review";
import * as styles from './bookAndReview.module.scss';


export class BookAndReview extends React.Component {
	

    render() {
        return (
            <div className={styles.container}>
                <Review review={this.props.book.reviews[0]} last={this.props.last}>
                    <Book book={this.props.book}/>
                </Review>
            </div>
        );
    }
}


BookAndReview.propTypes = {
    book: PropTypes.object.isRequired,
    last: PropTypes.bool.isRequired,

};



