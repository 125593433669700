import * as React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import {Pages, subd} from "./config/pages";

import {AboutPage} from "./content/pages/about/aboutPage";
import {BooksPage} from "./content/pages/books/booksPage";
import {TeachingPage} from "./content/pages/teaching/teachingPage";
import {ContactPage} from "./content/pages/contact/contactPage";

export function Root() {
    
    return (
        <BrowserRouter basename={subd}>
            <Switch>
                <Route exact={true} path={Pages.home} component={BooksPage}/>
                <Route exact={true} path={Pages.teaching.home} component={TeachingPage}/>
                <Route exact={true} path={Pages.about.home} component={AboutPage}/>
                <Route exact={true} path={Pages.contact.home} component={ContactPage}/>

            </Switch>
        </BrowserRouter>
    );
}