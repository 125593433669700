import * as React from 'react';
import PropTypes from "prop-types";
import * as styles from './contentPage.module.scss';
import {Page} from "../../common/page";
import {Menu} from "../../common/menu";
import {Link} from "../../common/link";
import {Pages} from "../../config/pages";
import {Footer} from './footerV';
import {name} from "../../common/contentAux";



export class ContentPage extends React.Component {

    

    render() {
        return (
            <Page>
                <div className={styles.whiteBox}>
                    <div className={styles.whiteOut}/>

                </div>

                <div className={styles.titleBox}>
                    <Link location={Pages.home} className={styles.title}>{name.toUpperCase()}</Link>

                </div>




                <div className={styles.outerBorder}>
                <div className={styles.innerBorder}>
                   

               	    <Menu activePage={this.props.activePage}/>

                    {this.props.children ? this.props.children : <div className={styles.content}/>}

              
                            
                </div>
                <Footer/>
                </div>
            </Page>
        );
    }
}

ContentPage.propTypes = {

    activePage: PropTypes.number.isRequired,


};
